import { createContext } from 'react';
import { ModalData } from './ModalContextProvider';

const ModalContext = createContext({
  component: '',
  props: {},
  showModal: (name: string, data: ModalData) => {},
  hideModal: () => {},
});

export const modalNames = {
  ImageModal: 'ImageModal',
};

export default ModalContext;
