export const loadImage = (path: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve(image);
    image.onerror = () => reject('error');
    image.src = path;
  });

export const loadImages = (...paths: string[]) => Promise.all(paths.map(loadImage));
