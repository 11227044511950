import React from 'react';
import { icons } from './icons';
import styles from './icon.module.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  name: keyof typeof icons;
  fillCurrentColor?: boolean;
}

const Icon: React.FC<Props> = ({ name, size, fillCurrentColor = true, className, ...props }) => {
  const dimensions = size ? { width: size, height: size } : undefined;
  const SelectedIcon = icons[name];

  if (process.env.NODE_ENV === 'development' && !SelectedIcon) {
    console.warn(`No such icon: ${SelectedIcon}`);
    return null;
  }

  return (
    <span
      className={`icon ${className} ${styles.icon} ${
        fillCurrentColor ? styles.fillCurrentColor : ''
      }`}
      style={dimensions}
      {...props}
    >
      <SelectedIcon />
    </span>
  );
};

export default Icon;
