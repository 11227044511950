import { Store } from 'use-global-hook';
import { AppActions, AppState } from '..';

export interface Photo {
  id: string;
  index: number,
  title: string;
  description: string;
  image: string;
  thumb: string;
}

const initialState = {
  photos: [],
};

const actions = {
  fetchData: async(store: Store<AppState, AppActions>) => {
    const { photos } = await fetch(
      `${process.env.PUBLIC_URL}/data/photos.json`,
      {
        method: 'GET',
      })
      .then(response => response.json());

    store.setState({
      ...store.state,
      photos: photos.map((item: any, index: number) => ({...item, id: `photo-${index}`, index})),
    });
  },
};

const location = {
  actions,
  initialState,
};

export default location;
