export const element = (elem, selector) => {
  return elem.querySelector(selector);
};
export default element;

export const elements = (elem, selector) => {
  return Array.from((elem).querySelectorAll(selector));
};

export const addClass = (element, className) => {
  if (className && !element.classList.contains(className)) {
    element.classList.add(className);
  }
};

export const addClasses = (element, classNames) => {
  classNames.forEach(className => addClass(element, className));
};

export const removeClass = (element, className) => {
  if (element.classList.contains(className)) {
    element.classList.remove(className);
  }
};

export const disableScroll = (disable, y) => {
  if (disable) {
    addClass(document.body, 'noscroll');
    document.body.style.top = `-${y}px`;
  } else {
    removeClass(document.body, 'noscroll');
    document.body.style.top = '0px';
    window.scroll({ top: y - 1 });
  }
};
