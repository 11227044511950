import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.scss';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
    const { t } = useTranslation('home');
    const location = useLocation();
    const [isHome, setIsHome] = useState<boolean>();

    useEffect(() => {
        setIsHome(location.pathname === process.env.PUBLIC_URL);
    }, [location.pathname]);

    return (
        <header className={`${styles.header} ${isHome && styles.home}`}>
            <Link to={`${process.env.PUBLIC_URL}`} className={styles.title}>
                <span className={`heading-03`}>{t('title')}</span>
            </Link>
            <nav className={styles.navigation}>
                <Link to={`${process.env.PUBLIC_URL}`} className={styles.link}>
                    <span className={styles.label}>Photos</span>
                </Link>
                <span>|</span>
                <Link to={`${process.env.PUBLIC_URL}/about`} className={styles.link}>
                    <span className={styles.label}>About Me</span>
                </Link>
            </nav>
        </header>
    );
}

export default Header;
