import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locale from '../locale';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    ns: ['global', 'home'],
    defaultNS: 'global',
    load: 'currentOnly',
    lowerCaseLng: true,
    detection: {
      order: ['path'],
    },
    resources: {
      ...locale
    },
    react: {
      useSuspense: false,
    },
    fallbackLng: "en-us",
    interpolation: {
      escapeValue: false
    }
  });
