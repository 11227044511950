import React from 'react';
import globalHook from 'use-global-hook';
import photos, { Photo } from './module/photos';

// Defining your own state and associated actions is required
export type AppState = {
  photos: Photo[];
};

// Associated actions are what's expected to be returned from globalHook
export type AppActions = {
  fetchData: () => void;
};

const initialState = {
  ...photos.initialState,
};

const actions = {
  ...photos.actions,
};

const useGlobal = globalHook<AppState, AppActions>(
    React,
    initialState,
    actions,
);

export default useGlobal;
