import React from 'react';
import styles from './About.module.scss';
// import Icon from '../../general/Icon';

const About: React.FC = () => {
    return (
        <section className={`section ${styles.about}`}>
            <div className={styles.wrapper}>
{/*
                <p className={`heading-01 ${styles.copy}`}>I'm a street photographer in <Icon name="sunny" className={styles.icon} /> sunny LA! I love taking pictures of people I meet on the street and capturing beautiful moments.</p>
*/}
                <p className={`heading-03 ${styles.copy}`}>
                    거리에서 만나게 되는 사람들의 모습을 담아보고 있습니다.
                </p>
                <br/>
                <br/>
                <p className={`heading-03 ${styles.copy}`}>
                    일반적으로 스트리트 포토그래피로 분류되는 사진들은 결정적 순간을 위해 조합되는 오브제로서 인물들을 사용하거나, 보도사진처럼 사건에 포커스를 두는 경향이 있습니다. 저는 거리에서 만나는 사람들에 집중하고  이들을 담담하고 과장 없는 색으로 담으려 하고 있습니다. 이런 시선으로 담긴 모습들이 누군가에게 영감을 줄 수 있으면 좋겠습니다.
                </p>
                <br/><br/><br/>
                <p className={`heading-03 ${styles.copy}`}>
                    이력 <br/>
                    Academy of Art University, San Francisco 졸업<br/>
                    Electronic Arts, Blur Studio, Activision Blizzard 등에서  필름이나 게임의 컴퓨터 그래픽과 관련된 작업을 담당. 현재 Los Angeles에 거주하며 Amazon의 게임부서에 캐릭터 아티스트로서 프로젝트에 참여 중.
                </p>
            </div>
        </section>
    );
};

export default About;
