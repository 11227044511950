import React, { useEffect, useState } from 'react';
import useGlobal from './store';
import { Routes, Route } from 'react-router-dom';
import './config/i18n';
import HomePage from './page/HomePage/HomePage';
import DetailPage from './page/DetailPage/DetailPage';
import './style/main.scss';
import Header from './component/layout/Header/Header';
import Footer from './component/layout/Footer/Footer';
import ModalContextProvider from './context/modal/ModalContextProvider';
import AboutPage from './page/AboutPage/AboutPage';

function App() {
    const { fetchData } = useGlobal()[1];
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ModalContextProvider>
            <div className="app">
                <Header />
                {mounted &&
                  <Routes>
                    <Route path={`${process.env.PUBLIC_URL}`} element={<HomePage />} />
                    <Route path={`${process.env.PUBLIC_URL}/photos`} element={<HomePage />} />
                    <Route path={`${process.env.PUBLIC_URL}/photos/:id`} element={<DetailPage />} />
                    <Route path={`${process.env.PUBLIC_URL}/about`} element={<AboutPage />} />
                  </Routes>
                }
                <Footer/>
            </div>
        </ModalContextProvider>
    );
}

export default App;
