import React, { useState } from 'react';
import ModalContext, { modalNames } from './ModalContext';
import ImageModal from '../../component/layout/modal/ImageModal';
import { disableScroll } from '../../util/element';


let prevScrollY = typeof window === 'object' ? window.pageYOffset : 0;

export interface ModalData {
  url: string,
  title: string,
}

interface ContextState {
  isOpen: boolean,
  component: string,
  data: ModalData,
}

// @ts-ignore
const ModalContextProvider = ({ children }) => {
  const showModal = (modalName: string, data: any) => {
    setState({
      ...state,
      isOpen: true,
      component: modalName,
      data,
    });

    prevScrollY = window.pageYOffset;
    disableScroll(true, prevScrollY);
  };

  const hideModal = () => {
    setState({
      ...state,
      isOpen: false,
      component: '',
      data: {
        url: '',
        title: '',
      },
    });

    disableScroll(false, prevScrollY);
  };

  const [state, setState] = useState<ContextState>(
    {
      isOpen: false,
      component: '',
      data: {
        url: '',
        title: '',
      },
    }
  );

  const value: any = {
    state,
    showModal,
    hideModal,
  }

  return (
    <ModalContext.Provider value={value}>
      {children}
      {state.component === modalNames.ImageModal && (
        <>
          <ImageModal isOpen={state.isOpen} data={state.data}/>
        </>
      )}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
