import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useGlobal from '../../../store';
import styles from './PhotoDetail.module.scss';
import { Photo } from '../../../store/module/photos';

interface WorkDetailProps {}

const PhotoDetail: React.FC<WorkDetailProps> = () => {
    const { id } = useParams<{id: string}>();
    const [{ photos }] = useGlobal();
    const [photo, setPhoto] = useState<Photo | null>(null);

    useEffect(() => {
        const image = photos.find((photo) => photo.id === id);
        if(image) {
            setPhoto(image);
        }
    }, [id, photos]);

    console.log('-');

    return (
        <section className={`${styles.photoDetail}`}>
            { (photo && photo.image) &&
            <>
                {(photo.description) &&
                    <div className={styles.copyContainer}>
                        {photo.description && <p className={`body`}>{photo.description}</p>}
                    </div>
                }
                <div className={styles.imageContainer}>
                    <img
                      className={styles.img}
                      src={`${process.env.PUBLIC_URL}${photo.image}`}
                      alt={`${photo.title || photo.description}}`}
                    />
                    <Link className={styles.navButton} to={`${process.env.PUBLIC_URL}/photos/photo-${photo.index > 0 ? photo.index - 1 : photos.length - 1}`}/>
                    <Link className={styles.navButton} to={`${process.env.PUBLIC_URL}/photos/photo-${photo.index < photos.length - 1 ? photo.index + 1 : 0}`}/>
              </div>
            </>
            }
            { !photo &&
            <h2>Image Not Found.</h2>
            }
        </section>
    );
}

export default PhotoDetail;
