import React from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../../../store';
import styles from './PhotoList.module.scss';

const PhotoList: React.FC = () => {
    const [{photos}] = useGlobal();

    return (
        <section className={`section`}>
            <ul className={styles.list}>
                {
                    photos.map(({id, description, thumb, image}, index) => (
                        <li className={styles.item} key={`photo ${index}`}>
                            <Link to={`${process.env.PUBLIC_URL}/photos/${id}`}>
                                <img className={styles.img} src={`${process.env.PUBLIC_URL}${thumb || image}`}
                                     alt={description}/>
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </section>
    );
};

export default PhotoList;
