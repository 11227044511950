import React from 'react';
import styles from './Footer.module.scss';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => (
    <footer className={`${styles.footer }`}>
        <ul>
            <li><span>GUN PARK</span></li>
            <li>
                <a className={styles.iconButton} href="mailto:kmcism@yahoo.com" target="_self">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M4.7 8.7c-.5 0-.9 0-1 .1s-.2.2-.1.3.3.3.7.6c.2.1.3.3.4.3l15.5 11.8 15.4-11.6.3-.3c.4-.3.6-.5.7-.6s.1-.2 0-.3c-.1-.1-.5-.2-1-.2H19.1L4.7 8.7z"/><path d="M2.6 11.5v17.4c0 .5.2 1 .6 1.5s.8.8 1.2.8h31.4c.4 0 .8-.3 1.2-.8.4-.5.6-1 .6-1.5V11.5L20.1 24.9 2.6 11.5z"/></svg>
                </a>
            </li>
        </ul>
    </footer>
);

export default Footer;
