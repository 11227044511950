import React, { useContext, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import ModalContext from '../../../../context/modal/ModalContext';
import { ModalData } from '../../../../context/modal/ModalContextProvider';
import Icon from '../../../general/Icon';
import styles from './image-modal.module.scss';
import { loadImage } from '../../../../util/image-loader';

gsap.registerPlugin(Draggable, InertiaPlugin);

interface ModalProps {
    isOpen: boolean;
    data: ModalData
}

const getBounds = (container: HTMLElement, child: HTMLElement) => {
    const {offsetWidth: imgWidth, offsetHeight: imgHeight} = child;
    const width = Math.min(imgWidth, (window.innerWidth - 60));
    const height = Math.min(imgHeight, (window.innerHeight - 100));
    container.style.width = `${width}px`;
    container.style.height = `${height}px`;

    return {
        minX: (width - child.offsetWidth),
        maxX: 0,
        minY: (container.offsetHeight - child.offsetHeight),
        maxY: 0,
    }
}

const ImageModal: React.FC<ModalProps> = ({ isOpen, data: { url, title } }) => {
    const modal = useContext(ModalContext);
    const [imgLoaded, setImgLoaded] = useState(false);
    const draggerRef = useRef<Draggable>();
    const contentRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        loadImage(url).then(() => {
            setImgLoaded(true);
        });
    }, [url]);

    useEffect(() => {
        if(!imageRef.current || !contentRef.current || !imgLoaded) {
            return;
        }

        draggerRef.current = Draggable.create(imageRef.current, {
            type: 'x,y',
            zIndexBoost: false,
            inertia: true,
            minimumMovement: 12,
            maxDuration: 1,
            dragResistance: 0.2,
            edgeResistance: 0.95,
            dragClickables: false,
            cursor: 'move',
            bounds: getBounds(contentRef.current, imageRef.current),
        })[0];

        return () => {
            if( draggerRef.current ) {
                draggerRef.current.kill();
                // @ts-ignore
                draggerRef.current = null;
            }
        };
    }, [imgLoaded]);

    const handleCloseClick = () => {
        modal.hideModal();
    };

    const handleResize = () => {
        if(!imageRef.current || !contentRef.current || !imgLoaded || !draggerRef.current) {
            return;
        }

        draggerRef.current.applyBounds(getBounds(contentRef.current, imageRef.current));
    }
    // window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <div className={`${styles.container} ${isOpen ? styles.isOpen : ''}`}>
            <button className={styles.bgButton} onClick={handleCloseClick} />
            <button className={styles.closeButton} onClick={handleCloseClick}>
                <Icon name="close" />
            </button>

            <div ref={contentRef} className={styles.contentWrapper}>
                <img ref={imageRef} className={styles.image} src={url} alt={title}/>
            </div>
        </div>
    );
};

export default ImageModal;
